// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { getCurrentCredentials } from './auth/fetchAuthSession';
import { Lambda } from 'aws-sdk';

// components
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Modal from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import MaterialTable, { Column as MTColumn } from '@material-table/core';

import moment from 'moment';
import type { AuthDetails } from './auth/midwayAuth';

import { S3Helper } from './S3Helper';

enum Status {
  None,
  Success,
  Error,
}

type SnopPublishProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
};

type UploadHistoryRec = {
  user: string;
  uploadTime: string;
  filePath: string;
  errorMsg?: string;
};

type validationDataRec = {
  dataset_name: string;
  ts: string;
  msg: string;
  status: string;
};

type pubMsgType = string | React.ReactElement;

const validationColumns: Array<MTColumn<validationDataRec>> = [
  { title: 'Dataset', field: 'dataset_name' },
  { title: 'Upload Time', field: 'ts' },
  { title: 'Status', field: 'status' },
  { title: 'Error Msg', field: 'msg' },
];

type UploadHistory = {
  latest_successes: UploadHistoryRec[];
  latest_error: UploadHistoryRec;
};

const requestColumns: Array<MTColumn<UploadHistoryRec>> = [
  { title: 'User', field: 'user' },
  { title: 'Upload Time', field: 'uploadTime' },
  { title: 'S3 File Path', field: 'filePath' },
  { title: 'Error', field: 'errorMsg' },
];

export type File = {
  name: string;
  path: string;
  size: number | string;
  error: boolean;
  errorMessage: string;
};

export const SnopPublish = (props: SnopPublishProps) => {
  useEffect(() => {
    setUsername(props.userInfo.username);
    setIsDev(props.projectEnv === 'Dev');
    props.setPageTitle(`SnOP Publish${props.projectEnv === 'Dev' ? ' - Beta' : ''}`);
    setFilePrefix(
      'web-input-data/' + props.projectName + '/' + props.userInfo.username + moment().utc().format('YYYYMMDD_hhmmss_'),
    );
  }, [props]);
  const [username, setUsername] = useState<string>();
  const [isDev, setIsDev] = useState<boolean>();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [historyData, setHistoryData] = useState<Array<UploadHistoryRec>>();
  const [uploadResponse, setUploadResponse] = useState<UploadHistory>();

  const [lvToggle, setLvToggle] = useState<boolean>(true);
  const [validationData, setValidationData] = useState<Array<validationDataRec>>();

  const [publishModel, setPublishModal] = useState<boolean>(false);
  const [disablePublish, setDisablePublish] = useState<boolean>(true);
  const [publishMsg, setPublishMsg] = useState<pubMsgType>('');
  const [publishing, setPublishing] = useState<boolean>(false);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [refreshUploads, setRefreshUploads] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [filePrefix, setFilePrefix] = React.useState<string>('');

  const [submitted, setSubmitted] = useState<boolean>(false);
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const callDelegation = async (userMethodParams: any, successCallBack: Function, errorCallBack: Function) => {
    console.log('callDelegation');
    let auth = await getCurrentCredentials();
    var lambdaClient = new Lambda({
      region: 'us-east-1',
      credentials: auth,
    });
    console.debug(
      JSON.stringify({
        function: 'callDelegation',
        auth: auth,
      }),
    );
    console.log(
      JSON.stringify({
        function: 'callDelegation',
        userMethodParams: userMethodParams,
      }),
    );
    var res = lambdaClient.invoke(
      {
        FunctionName: 'arn:aws:lambda:us-east-1:336473673731:function:IRISPythonLambdas-firebolt_delegation4',
        Payload: JSON.stringify(userMethodParams),
      },
      (err, data) => {
        if (data && data.Payload && typeof data.Payload == 'string') {
          console.log('callDelegation callback');
          console.log(typeof data.Payload);
          console.log(data.Payload);
          successCallBack(JSON.parse(data.Payload));
          console.log('callDelegation callback after');
        } else if (err) {
          errorCallBack(true);
        }
      },
    );
    return res;
  };

  const handleFileAttached = (acceptedFiles: File[]) => {
    if (files?.length > 0) {
      const newFiles = [...files, ...acceptedFiles];
      const uniqueFiles = Array.from(new Set(newFiles.map((a) => a.path))).map(
        (path): File => newFiles.find((a) => a.path === path)!,
      );
      setFiles(uniqueFiles);
    } else {
      setFiles(acceptedFiles);
    }
  };

  const handleFilesSubmit = async () => {
    if (files.length === 0) {
      setIsUploading(false);
    } else {
      setIsUploading(true);
      var fileName: string;
      try {
        var s3Key;
        await Promise.all(
          files.map(async (file) => {
            fileName = filePrefix + file.path;
            console.log(fileName);
            s3Key = await S3Helper.put(fileName, file, {
              contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return JSON.stringify({ path: file.path, s3Key: s3Key.key });
          }),
        );
        var params = {
          projectName: props.projectName,
          datasetName: 'SnOP_Publish',
          uploadKey: 'public/' + s3Key.key,
          requestUserId: username,
          isDev: isDev,
          operation: 'ValidatePublishUpload',
        };
        console.log(params);
        setTimeout(() => {
          setLvToggle(!lvToggle);
        }, 2000);
        var data = callDelegation(
          params,
          (res) => {
            setUploadResponse(res);
            setRefreshUploads(true);
            setSubmitted(true);
            setFiles([]);
            setIsUploading(false);
            params.operation = 'Validation';
            let data = callDelegation(
              params,
              (res) => {
                console.log('success call validation');
                setLvToggle(lvToggle);
              },
              (res) => {
                console.log('error call validation');
                setLvToggle(lvToggle);
              },
            );
            console.log(data);
          },
          (res) => {
            console.log('error call handleSubmit');
            console.log(res);
            setSubmitted(true);
            setFiles([]);
            setIsUploading(false);
          },
        );
        console.log(JSON.stringify(data));
      } catch (err) {
        console.log('Upload Failed', err);
        setSubmitted(true);
      }
    }
  };

  const getUploads = () => {
    try {
      let params = {
        requestUserId: username,
        isDev: isDev,
        operation: 'LatestPublishUpload',
      };
      var data = callDelegation(
        params,
        (res) => {
          setUploadResponse(res);
          console.log('success call handleSubmit');
          console.log(res);
        },
        (res) => {
          console.log('error call handleSubmit');
          console.log(res);
        },
      );
      console.log(JSON.stringify(data));
    } catch (err) {
      console.log('Upload Failed', err);
    }
  };

  const validationMsgMapper = (vRec: validationDataRec): validationDataRec => {
    if (vRec.msg !== '' && vRec.msg.includes(';')) {
      let msgSplit = vRec.msg.split('; ');
      var validationType = msgSplit[1];

      var validationProject = '';
      var validationSheet = '';
      var validationCol = '';
      var validationFirstRow = '';
      if (msgSplit[2].includes('-')) {
        let validationProjectSheetCol = msgSplit[2].split('-');
        validationProject = validationProjectSheetCol[0];
        validationSheet = validationProjectSheetCol[1];
        validationCol = validationProjectSheetCol[2];
      }
      if (msgSplit[4].includes('(up to 10) - ')) {
        let validationRowPre = msgSplit[4].split('(up to 10) - ');
        validationFirstRow = validationRowPre[1].split(',')[0];
        validationFirstRow = String(parseInt(validationFirstRow) + 1);
      } else {
        validationFirstRow = '-1';
      }

      console.log(
        'validationMsgMapper',
        validationType,
        validationProject,
        validationSheet,
        validationCol,
        validationFirstRow,
      );
      var newMsg = '';
      if (validationType === 'ExtraWhitespaceValidation') {
        newMsg =
          'Input cannot have an extra space character: Sheet ' +
          validationSheet +
          ', Column ' +
          validationCol +
          ', Line ' +
          validationFirstRow;
      } else if (validationType === 'WithinExoValidation') {
        if (validationCol === 'Day Of Week') {
          newMsg =
            'Unexpected value for Day of Week: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'Site') {
          newMsg =
            'Site name does not exist: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (['Start Date', 'End Date'].includes(validationCol)) {
          newMsg =
            'Invalid Start Date/End Date: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'Channel') {
          newMsg =
            'Invalid Channel name: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'Window') {
          newMsg =
            'Invalid Window name: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'SnOP_Week') {
          newMsg =
            'Invalid SnOP Week: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (['Source', 'Destination'].includes(validationCol)) {
          newMsg =
            'Invalid Source/Destination: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'Week_Start_Date') {
          newMsg =
            'Invalid Week_Start_Date: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else if (validationCol === 'Process_Path') {
          newMsg =
            'Invalid Process_Path: Sheet ' +
            validationSheet +
            ', Column ' +
            validationCol +
            ', Line ' +
            validationFirstRow;
        } else {
          newMsg = 'Malformed Error Msg; Contact help';
        }
      }

      vRec.msg = newMsg;
    }
    // "Value cannot be null for Column XX, Line XX"
    // "Input cannot have an extra space character at Column XX, Line XX"
    return vRec;
  };

  const getLatestValidations = () => {
    try {
      let params = {
        projectName: props.projectName,
        operation: 'LatestValidations',
      };
      var data = callDelegation(
        params,
        (res) => {
          console.log(res);
          if (res.latestValidations) {
            console.log(res.latestValidations);
            setValidationData(res.latestValidations.map(validationMsgMapper));
          }
          console.log('success call handleSubmit');
        },
        (res) => {
          console.log(res);
          console.log('error call handleSubmit');
        },
      );
      console.log(JSON.stringify(data));
    } catch (err) {
      console.log('getLatestValidations Failed', err);
    }
  };

  const publishModalTrue = () => setPublishModal(true);
  const publishModalFalse = () => setPublishModal(false);

  const handlePublish = () => {
    try {
      setPublishMsg('');
      setPublishing(true);
      let params = {
        requestUserId: username,
        isDev: isDev,
        operation: 'SubmitPublishUpload',
      };
      var data = callDelegation(
        params,
        (res) => {
          console.log(JSON.stringify(res));
          console.log(typeof res);
          if (res && res.success) {
            console.log('inside res.success');
            setPublishMsg(
              <Column>
                <Row>
                  <a href="https://datacentral.a2z.com/dw-platform/servlet/dwp/template/DWPJobPerformanceHistory.vm/job_id/25944894">
                    {'[Transform] fawkes_ssd_snop_publish'}
                  </a>
                </Row>
                <Row>
                  <a href="https://datacentral.a2z.com/dw-platform/servlet/dwp/template/DWPJobPerformanceHistory.vm/job_id/25944665">
                    {'[BDCL] fawkes_ssd_snop_publish'}
                  </a>
                </Row>
              </Column>,
            );
          } else if (res && res.errorMessage) {
            console.log('inside res.success');
            setPublishMsg(res.errorMessage);
          }
          setPublishing(false);
        },
        (res) => {
          console.log('error call handleSubmit');
          console.log(res);
          setPublishing(false);
        },
      );
      console.log(JSON.stringify(data));
    } catch (err) {
      console.log('Upload Failed', err);
    }
  };

  useEffect(() => {
    if (username) {
      getUploads();
      getLatestValidations();
    }
  }, [username]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (username) {
      getLatestValidations();
    }
  }, [lvToggle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(uploadResponse);
    if (uploadResponse) {
      var historyRecs = [] as UploadHistoryRec[];
      var latestStatus = Status.None;
      if (uploadResponse.latest_error && uploadResponse.latest_error.errorMsg) {
        console.log('Adding latest_error');
        historyRecs.push(uploadResponse.latest_error);
        latestStatus = Status.Error;
      }
      if (uploadResponse.latest_successes && uploadResponse.latest_successes.length > 0) {
        console.log('Adding latest_successes: ' + uploadResponse.latest_successes.length);
        historyRecs = historyRecs.concat(uploadResponse.latest_successes.reverse());
        latestStatus = latestStatus === Status.Error ? Status.Error : Status.Success;
      }
      if (historyRecs.length > 0) {
        setIsDataLoading(false);
        console.log('historyRecs to render: ' + historyRecs.length);
        setHistoryData(historyRecs);
        if (latestStatus === Status.Success) {
          setDisablePublish(false);
        }
      }
    }
  }, [uploadResponse]);

  useEffect(() => {
    console.log('useEffect validationData');
    if (validationData) {
      console.log('HERE');
      console.log(JSON.stringify(validationData));
    }
  }, [validationData]);

  useEffect(() => {
    console.log('useeffect submitted');
    if (submitted && uploadResponse && uploadResponse.latest_error && uploadResponse.latest_error.errorMsg) {
      setDisablePublish(true);
    }
  }, [submitted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('useeffect refreshUploads');
    if (refreshUploads) {
      console.log('sleep start');
      setRefreshUploads(false);
      sleep(2500).then(
        () => {
          console.log('sleep end');
          getUploads();
          setSubmitted(false);
        },
        () => {
          console.error('sleep error');
        },
      );
    }
  }, [refreshUploads]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Heading level={5}>Select the Input Files:</Heading>
          <Column spacing="large">
            <FileInput onFileAttached={handleFileAttached} type="single" accept=".xlsx">
              {files?.map((file) => {
                return (
                  <FileDetails
                    error={file.error}
                    errorMessage={file.errorMessage}
                    file={file}
                    key={file.name}
                    onClickRemoveFile={() => {
                      const updatedFileArr = files.filter((fl) => fl.name !== file.name);
                      setFiles(updatedFileArr);
                    }}
                    uploadComplete={true}
                  />
                );
              })}
            </FileInput>
            <Row>
              <Button onClick={handleFilesSubmit} disabled={!(files?.length > 0) || isUploading}>
                Upload
              </Button>
              {isUploading ? <Loader /> : null}
            </Row>
          </Column>
        </Box>
      </Row>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          {!isDataLoading ? (
            validationData ? (
              <MaterialTable
                style={{ zIndex: 1 }}
                columns={validationColumns}
                data={validationData}
                title="Dataset Validation"
                options={{
                  search: false,
                  paging: false,
                  pageSize: 25,
                  emptyRowsWhenPaging: false,
                  headerStyle: { backgroundColor: '#11ffee00', position: 'sticky', top: 0, fontWeight: 'bold' },
                }}
              />
            ) : null
          ) : (
            <Loader />
          )}
        </Box>
      </Row>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          {!isDataLoading ? (
            historyData ? (
              <MaterialTable
                style={{ zIndex: 1 }}
                columns={requestColumns}
                data={historyData}
                title="Recent Uploads"
                options={{
                  search: false,
                  paging: false,
                  pageSize: 25,
                  emptyRowsWhenPaging: false,
                  headerStyle: { backgroundColor: '#11ffee00', position: 'sticky', top: 0, fontWeight: 'bold' },
                }}
              />
            ) : null
          ) : (
            <Loader />
          )}
        </Box>
      </Row>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Button onClick={publishModalTrue} disabled={disablePublish}>
            Publish
          </Button>
          {publishing ? <Loader /> : publishMsg}
        </Box>
      </Row>
      <Modal open={publishModel} onClose={publishModalFalse}>
        <Row alignmentHorizontal="start" widths="fit" minHeight={50}>
          <Text id="modal-description">New dataset will be published to Andes, are you sure?</Text>
        </Row>
        <Row alignmentHorizontal="center" widths="fit">
          <Button type="secondary" size="small" onClick={handlePublish}>
            Publish
          </Button>
          <Button type="secondary" size="small" onClick={publishModalFalse}>
            Cancel
          </Button>
        </Row>
      </Modal>
    </>
  );
};
