import AWS from 'aws-sdk';
import { getCurrentCredentials } from './auth/fetchAuthSession';

const initSQS = async () => {
  const currentCredentials = await getCurrentCredentials();
  if (!currentCredentials) {
    throw new Error('Failed to get credentials');
  }

  const sqs = new AWS.SQS({
    credentials: new AWS.Credentials({
      accessKeyId: currentCredentials.accessKeyId,
      secretAccessKey: currentCredentials.secretAccessKey,
      sessionToken: currentCredentials.sessionToken,
    }),
  });

  return sqs;
};

export const sendSQSTCalcBuilder = async (projectName: string, projectEnv: string, messageBody: string) => {
  const sqs = await initSQS();
  console.log('Sending SQS');
  var sqsParams = {
    MessageGroupId: (projectName + (projectEnv === 'Dev' ? 'Dev' : 'Prod')).replace(/\s+/g, ''),
    MessageBody: messageBody,
    // MessageDeduplicationId: "TheWhistler",  // Required for FIFO queues
    QueueUrl: 'https://sqs.us-east-1.amazonaws.com/336473673731/FireBolt-Recalc-Queue.fifo',
  };
  const SQSSendStatus = await sqs
    .sendMessage(sqsParams)
    .promise()
    .catch((err) => {
      console.log(err);
    });
  console.log(SQSSendStatus);
  return;
};
