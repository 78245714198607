// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { API } from './API';

// components
//import { ErrorDisplay } from "./ErrorDisplay";
import { MultiSelect } from 'react-multi-select-component';

import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';

import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';

import DateRangePicker from '@amzn/meridian/date-range-picker';
import { format, subDays } from 'date-fns';

import { Option, DimOptions, DropdownAttributes } from './Types';
import type { AuthDetails } from './auth/midwayAuth';
import Checkbox from '@amzn/meridian/checkbox';
import { downloadFromS3, waitForS3File } from './CommonFunctions';
import moment from 'moment';
import { gunzipSync } from 'react-zlib-js';
import { Buffer } from 'buffer';

export type File = {
  name: string;
  path: string;
  size: number | string;
  error: boolean;
  errorMessage: string;
};

type ReviewDataViewProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
  dataType: string;
  recalcInProgress?: boolean;
  dropDownStatus: {
    IsLoaded: boolean;
    SetIsLoaded: Function;
    Attributes: DropdownAttributes | undefined;
    SetAttributes: Function;
    SelectedMetrics: Option[];
    SetSelectedMetrics: Function;
    DisplaySelecteddimList: DimOptions;
    SetDisplaySelecteddimList: Function;
    SelectedUploadTimes: Option[];
    SetSelectedUploadTimes: Function;
  };
  projectUserAccess?: string;
};

const ReviewDataViewConfig = {
  InputData: {
    pageTitle: 'Review Input Data',
    operation: 'inputData',
  },
  ResultsAndOverride: {
    pageTitle: 'Review Results & Edit Overrides',
    operation: 'resultAndOverrideData',
  },
};

//type SelectedS3FileSheet = {
//    [key: string]: string[]
//}

/**
 * @props:
 *      onReviewDataViewFilesSubmit: function called when user submits files
 *      error: error message to display for nav ReviewDataView file submits
 *      display_results: whether or not we should display the results on the map, controlled by toggle
 *      setDisplayResults: function to set whether or not to display the results on the map
 */
//let displaySelecteddimList: DimOptions = {};
const dateFormat = 'yyyy-MM-dd';

export const ReviewDataView = (props: ReviewDataViewProps) => {
  //const [metricList, setmetricList] = React.useState<Option[]>();
  //const [dimList, setDimList] = React.useState<DimOptions>({});
  //const [selectedDims, setSelectedDims] = React.useState<Option[]>([]);
  useEffect(() => {
    props.setPageTitle(
      `${ReviewDataViewConfig[props.dataType].pageTitle}${props.projectEnv === 'Dev' ? ' - Beta' : ''}`,
    );
    setFireboltTableData([]);
    setFireboltTableHeaders([]);
    setIsDataLoaded(false);
    setFireboltTableDataUpdatedKeys([]);
    setDateRange([
      props.dataType === 'InputData' ? '' : format(subDays(new Date(), 30), dateFormat),
      props.dataType === 'InputData' ? '' : format(new Date(), dateFormat),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataType, props.projectName, props.projectEnv]);

  const [onlyLatestCheckStatus, setOnlyLatestCheckStatus] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isTableRowUpdated, setIsTableRowUpdated] = useState<boolean>(false);
  const [fireboltTableHeaders, setFireboltTableHeaders] = useState<Object[]>([]);
  const [fireboltTableData, setFireboltTableData] = useState<Object[]>([]);

  const [includeChildrenStatus, setIncludeChildrenStatus] = useState<boolean>(false);

  const [fireboltTableDataUpdatedKeys, setFireboltTableDataUpdatedKeys] = useState<number[]>([]);

  const [dateRange, setDateRange] = useState([
    props.dataType === 'InputData' ? '' : format(subDays(new Date(), 30), dateFormat),
    props.dataType === 'InputData' ? '' : format(new Date(), dateFormat),
  ]);

  useEffect(() => {
    if (!props.dropDownStatus.IsLoaded) {
      const getDropdownAttributes = async () => {
        var apiCallParam = {
          headers: {},
          queryStringParameters: {
            operation: 'getDropdownValues',
            userId: props.userInfo.username,
            projectName: props.projectName,
            projectEnv: props.projectEnv,
          },
        };
        const data = await API.get('FireboltWebsiteAPIs', '/reviewdata', apiCallParam);
        //setDropdownAttributes(data)
        const tmpDropdownAttributes: DropdownAttributes = {
          MetricList: data.MetricList,
          DimList: data.DimList,
          UploadTimes: data.UploadTimes,
        };
        if (tmpDropdownAttributes.MetricList.length > 0) {
          props.dropDownStatus.SetAttributes(tmpDropdownAttributes);
          var tmpDisplaySelecteddimList = {};
          Object.keys(data.DimList).map((e) => (tmpDisplaySelecteddimList[e] = []));
          props.dropDownStatus.SetDisplaySelecteddimList(tmpDisplaySelecteddimList);
          props.dropDownStatus.SetIsLoaded(true);
        }
      };
      getDropdownAttributes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedDimsCustom = (value: Option[], key) => {
    props.dropDownStatus.DisplaySelecteddimList[key] = value;
    props.dropDownStatus.SetDisplaySelecteddimList({ ...props.dropDownStatus.DisplaySelecteddimList });
  };

  const handleViewSubmit = async () => {
    setIsDataLoading(true);
    const slectedMetricListSubmitted =
      props.dropDownStatus.SelectedMetrics.length === 0 ||
      props.dropDownStatus.SelectedMetrics.length === props.dropDownStatus.Attributes?.MetricList.length
        ? `%`
        : props.dropDownStatus.SelectedMetrics!.map((e) => e.value);
    const slectedUploadTimesSubmitted = props.dropDownStatus.SelectedUploadTimes.map((e) => e.value);

    //var slectedDimSubmitted: string[][] = [];

    const dimSearchString = Object.entries(props.dropDownStatus.DisplaySelecteddimList)
      .map(([key, value]) =>
        value.length === 0 || value.length === props.dropDownStatus.Attributes?.DimList[key].length
          ? '%'
          : `%(` + value.map((e) => e.value.replace(/[{}]/g, '')).join('|') + `)%`,
      )
      .join('');

    /*
                Object.keys(props.dropDownStatus.Attributes?.DimList!).map(key => {
                    const tmpDimKeySubmitted = props.dropDownStatus.DisplaySelecteddimList[key].length === 0 ? props.dropDownStatus.Attributes?.DimList[key]! : props.dropDownStatus.DisplaySelecteddimList[key]
                    slectedDimSubmitted.push(tmpDimKeySubmitted.map(e => e.value));
                    return 0;
                });
        */
    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: ReviewDataViewConfig[props.dataType].operation,
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        dateRange: dateRange,
        slectedMetricListSubmitted: slectedMetricListSubmitted,
        ...(slectedUploadTimesSubmitted.length > 0 &&
          props.dataType === 'InputData' && { slectedUploadTimesSubmitted: slectedUploadTimesSubmitted }),
        ...(props.dataType === 'InputData' && { onlyLatestCheckStatus: onlyLatestCheckStatus }),
        slectedDimSubmitted: dimSearchString,
      },
    };

    const response = await API.get('FireboltWebsiteAPIs', '/reviewdata', apiCallParam);
    const resp = Buffer.from(response.toString(), 'hex');
    const data = JSON.parse(gunzipSync(resp));
    console.log(data);
    if (data) {
      setFireboltTableHeaders(data.dataHeader);
      setFireboltTableData(data.dataBody);
      setIsDataLoaded(true);
    }
    setIsDataLoading(false);
    setIsTableRowUpdated(false);
  };

  const handleDownloadExcel = async () => {
    setIsDataLoading(true);

    //var slectedDimSubmitted: string[][] = [];
    const slectedMetricListSubmitted =
      props.dropDownStatus.SelectedMetrics.length === 0
        ? `%`
        : props.dropDownStatus.SelectedMetrics!.map((e) => e.value);
    const dimSearchString = Object.entries(props.dropDownStatus.DisplaySelecteddimList)
      .map(([key, value]) =>
        value.length === 0 || value.length === props.dropDownStatus.Attributes?.DimList[key].length
          ? '%"' + key + '":%'
          : `%(` + value.map((e) => e.value.replace(/[{}]/g, '')).join('|') + '|"' + key + `":"All")%`,
      )
      .join('');

    const excelFileName =
      props.userInfo.username +
      '_' +
      moment().utc().format('YYYYMMDD_hhmmss_') +
      props.projectName +
      '_' +
      props.projectEnv +
      '_Results.xlsx';
    const s3ExcelFileKey = 'tmpFiles/' + props.projectName + '/' + excelFileName; //.replace(/\ /g, '_')
    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: 'downloadResultsToExcel',
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        dateRange: dateRange,
        slectedDimSubmitted: dimSearchString,
        downloadFileName: 'public/' + s3ExcelFileKey,
        recurseToChildren: includeChildrenStatus,
        slectedMetricListSubmitted: slectedMetricListSubmitted,
      },
    };

    try {
      await API.get('FireboltWebsiteAPIs', '/reviewdata', apiCallParam);
    } catch (err) {
      await waitForS3File(s3ExcelFileKey, 10000, 18);
    }
    downloadFromS3(s3ExcelFileKey, excelFileName);

    setIsDataLoading(false);
  };

  const handleUpdateSubmit = async () => {
    setIsDataLoading(true);
    //console.log(fireboltTableDataUpdatedKeys)
    const tmpUpdatedRows = fireboltTableData.filter((e) => fireboltTableDataUpdatedKeys.includes((e as any).Row_ID));
    //console.log(tmpUpdatedRows)
    const updatedRows = tmpUpdatedRows.map(function (item: any) {
      delete item.Last_Updated;
      delete item.Row_ID;
      delete item.Value_Without_Override;
      return item;
    });

    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: 'webOverrideData',
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        userId: props.userInfo.username,
        updatedOverrideData: JSON.stringify(updatedRows),
      },
    };

    await API.get('FireboltWebsiteAPIs', '/uploadinputdatafiles', apiCallParam);
    //console.log(data)
    //console.log(updatedRows)
    setIsDataLoading(false);
    setIsTableRowUpdated(false);
    setFireboltTableDataUpdatedKeys([]);
    //setFireboltTableData([])
    //handleViewSubmit();
  };

  return props.dropDownStatus.IsLoaded && props.dropDownStatus.Attributes !== undefined ? (
    <>
      <Row width="100%" widths="fill" height="100%">
        <Box spacingInset="medium" height="100%">
          <Column spacing="medium">
            <Row width="100%" widths="fill" height="auto" alignmentHorizontal="start" alignmentVertical="top">
              <Heading level={5}>Select Filter Items:</Heading>
            </Row>
            <Row
              width="100%"
              widths="fit"
              height="auto"
              alignmentHorizontal="start"
              alignmentVertical="top"
              spacing="small"
            >
              <Column>
                <Row width="100%" widths="fill" height="100%">
                  <Text>Date Range</Text>
                </Row>
                <Row alignmentHorizontal="start">
                  <div>
                    <DateRangePicker
                      value={dateRange}
                      allowSameStartAndEnd={true}
                      onChange={setDateRange}
                      startLabel="From"
                      endLabel="To"
                      monthsInView={2}
                      size="medium"
                    />
                  </div>
                </Row>
              </Column>
            </Row>
            <div style={{ zIndex: 20 }}>
              <Row width="100%" widths="fill" wrap="down">
                <Box maxWidth="25%" minWidth={150}>
                  <Row width="100%" widths="fill" height="100%">
                    <Text>Metrics</Text>
                  </Row>
                  <Row key="MetricRow" width="100%" widths="fill" height="100%">
                    <div style={{ width: '10px' }}>
                      <MultiSelect
                        key="MetricList"
                        options={props.dropDownStatus.Attributes?.MetricList!}
                        value={props.dropDownStatus.SelectedMetrics}
                        onChange={props.dropDownStatus.SetSelectedMetrics}
                        labelledBy="Select Metrics .."
                      />
                    </div>
                  </Row>
                </Box>
                {Object.keys(props.dropDownStatus.Attributes?.DimList!).map((key) => (
                  <Box maxWidth="25%" minWidth={150}>
                    <Row width="100%" widths="fill" height="100%">
                      <Text>{key}</Text>
                    </Row>
                    <Row width="100%" widths="fill" height="100%">
                      <div style={{ width: '10px' }}>
                        <MultiSelect
                          options={props.dropDownStatus.Attributes?.DimList[key]!}
                          value={props.dropDownStatus.DisplaySelecteddimList[key]}
                          onChange={(value: Option[]) => setSelectedDimsCustom(value, key)}
                          labelledBy={'Select ' + key + ' ..'}
                        />
                      </div>
                    </Row>
                  </Box>
                ))}

                {props.dataType === 'InputData' ? (
                  <>
                    <Column width="5%">
                      <Row width="100%" widths="fill" height="100%">
                        <Text>Only Latest Values?</Text>
                      </Row>
                      <Row width="100%" widths="fill" height="100%">
                        <Checkbox checked={onlyLatestCheckStatus} onChange={setOnlyLatestCheckStatus}></Checkbox>
                      </Row>
                    </Column>
                    <Column width="15%">
                      <Row width="100%" widths="fill" height="100%">
                        <Text>Upload Time</Text>
                      </Row>
                      <Row width="100%" widths="fill" height="100%">
                        <div style={{ width: '10px' }}>
                          <MultiSelect
                            key="UploadTimes"
                            options={props.dropDownStatus.Attributes?.UploadTimes!}
                            value={props.dropDownStatus.SelectedUploadTimes}
                            onChange={props.dropDownStatus.SetSelectedUploadTimes}
                            labelledBy="Select Upload Time .."
                          />
                        </div>
                      </Row>
                    </Column>
                  </>
                ) : null}
              </Row>
            </div>
            <Row width="fill">
              <Column width="33%" alignmentHorizontal="start">
                <Button onClick={handleViewSubmit}> View </Button>
              </Column>
              <Column width="33%" alignmentHorizontal="center">
                {props.dataType === 'ResultsAndOverride' ? (
                  <Box>
                    <Row>
                      <Column width="65%" alignmentHorizontal="start">
                        <Checkbox checked={includeChildrenStatus} onChange={setIncludeChildrenStatus}>
                          Include Child Metrics
                        </Checkbox>
                      </Column>
                      <Column width="35%" alignmentHorizontal="end">
                        <Button onClick={handleDownloadExcel}> Download Excel </Button>
                      </Column>
                    </Row>
                  </Box>
                ) : null}
              </Column>
              <Column width="33%" alignmentHorizontal="end">
                {props.dataType === 'ResultsAndOverride' ? (
                  <Button
                    onClick={handleUpdateSubmit}
                    disabled={
                      (props.recalcInProgress !== undefined && props.recalcInProgress) ||
                      !isDataLoaded ||
                      props.projectUserAccess === 'User' ||
                      !isTableRowUpdated
                    }
                  >
                    {' '}
                    Update DB{' '}
                  </Button>
                ) : null}
              </Column>
            </Row>

            <Row width="100%" widths="fill">
              <Box type="outline" height="100%">
                {!isDataLoading ? (
                  isDataLoaded ? (
                    <MaterialTable
                      style={{ zIndex: 1 }}
                      columns={fireboltTableHeaders}
                      data={fireboltTableData}
                      title=""
                      components={
                        props.dataType === 'ResultsAndOverride'
                          ? {
                              Row: (props) => (
                                <MTableBodyRow
                                  {...props}
                                  onDoubleClick={(e) => {
                                    props.actions[0]().onClick(e, props.data);
                                  }}
                                />
                              ),
                            }
                          : undefined
                      }
                      editable={
                        props.dataType === 'ResultsAndOverride' && props.projectUserAccess !== 'User'
                          ? {
                              onRowUpdate: (newData, oldData) => {
                                return new Promise<void>((resolve, reject) => {
                                  setTimeout(() => {
                                    const dataUpdate = [...fireboltTableData];
                                    // In dataUpdate, find target
                                    //const target = dataUpdate.find((el: any) => el.Row_ID === (oldData as any).tableData.id);
                                    //const index = dataUpdate.indexOf(target!);
                                    //console.log((oldData as any).tableData.id)
                                    //console.log((oldData as any))

                                    //dataUpdate[(oldData as any).tableData.id] = newData;
                                    dataUpdate[(oldData as any).Row_ID]['Override_Value'] = newData['Override_Value'];
                                    setFireboltTableData([...dataUpdate]);
                                    setFireboltTableDataUpdatedKeys([
                                      ...fireboltTableDataUpdatedKeys,
                                      (oldData as any).Row_ID,
                                    ]);
                                    setIsTableRowUpdated(true);
                                    resolve();
                                  }, 1000);
                                });
                              },
                            }
                          : undefined
                      }
                      options={{
                        paging: true,
                        pageSize: 10,
                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                        pageSizeOptions: [10, 20, 40, 80, 160, 320], // rows selection options
                        exportAllData: true,
                        headerStyle: { backgroundColor: '#11ffee00', position: 'sticky', top: 0, fontWeight: 'bold' },
                        exportMenu: [
                          {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, 'myCsvFileName'),
                          },
                        ],
                      }}
                    />
                  ) : null
                ) : (
                  <Loader />
                )}
              </Box>
            </Row>
          </Column>
        </Box>
      </Row>
    </>
  ) : (
    <Loader />
  );
};
