// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { API } from './API';

import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';

import MaterialTable from '@material-table/core';

import type { AuthDetails } from './auth/midwayAuth';

type HomeProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
};

export const HomeView = (props: HomeProps) => {
  useEffect(() => {
    props.setPageTitle(
      `Home${window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('beta') ? ' - Beta' : ''}`,
    );
    setFireboltLogTableData([]);
    setFireboltLogTableHeaders([]);
    setIsDataLoaded(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [fireboltTableHeaders, setFireboltLogTableHeaders] = useState<Object[]>([]);
  const [fireboltTableData, setFireboltLogTableData] = useState<Object[]>([]);

  useEffect(() => {
    const getlogData = async () => {
      var apiCallParam = {
        headers: {},
        queryStringParameters: {
          operation: 'logData',
          projectName: props.projectName,
          projectEnv: props.projectEnv,
        },
      };
      const data = await API.get('FireboltWebsiteAPIs', '/reviewdata', apiCallParam);
      if (data) {
        setFireboltLogTableHeaders(data.dataHeader);
        setFireboltLogTableData(data.dataBody);
        setIsDataLoaded(true);
      }

      setIsDataLoaded(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getlogData();
  }, [props.projectName, props.projectEnv]);

  return isDataLoaded ? (
    <>
      <Row width="100%" widths="fill" height="100%">
        <Box type="outline" spacingInset="medium" height="100%">
          <Column spacing="medium">
            <MaterialTable
              style={{ zIndex: 1 }}
              columns={fireboltTableHeaders}
              data={fireboltTableData}
              title="Project Activity Log"
              options={{
                paging: true,
                pageSize: 20,
                search: false,
              }}
            />
          </Column>
        </Box>
      </Row>
    </>
  ) : (
    <Loader />
  );
};
