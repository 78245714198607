import { Cache } from '@aws-amplify/core';

import { TokenRetriever } from './tokenRetriever';

/**
 * Copy of a package we don't want to depend on:
 * https://code.amazon.com/packages/Westgabr-talent-appsync/blobs/mainline/--/src/index.js
 * this removes query parameters that midway adds after a redirection
 */
const deleteQueryParameter = function (key: string) {
  const queryParams = new URLSearchParams(window.location.search);
  if (!queryParams.get(key)) {
    return;
  }
  queryParams.delete(key);
  const newUrl = new URL(window.location.href);

  newUrl.search = queryParams.toString();
  window.history.replaceState({}, '', newUrl.toString());
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    return null;
  }
};

const parseUser = function (token: string) {
  const parsedToken = parseJwt(token);
  return {
    username: parsedToken.sub,
    name: parsedToken.sub,
  };
};

const getMidwayToken = async function () {
  const cachedFederatedInfo = await Cache.getItem('federatedInfo');
  const currentTimestamp = Date.now();

  let midwayToken: any;
  const oneSecond = 1000;

  if (cachedFederatedInfo && currentTimestamp < cachedFederatedInfo.expires_at * oneSecond) {
    midwayToken = cachedFederatedInfo.token;
  } else {
    midwayToken = await TokenRetriever.getTokenOrRedirect();
  }
  deleteQueryParameter('id_token');
  deleteQueryParameter('state');
  return midwayToken;
};

export const refreshMidway = async function () {
  const token = await getMidwayToken();
  const parsedToken = parseJwt(token);
  return {
    token,
    expires_at: parsedToken.exp,
  };
};

export const getUsername = async function getUsername(data: any) {
  let token;
  if (data?.token) {
    token = data.token;
  } else {
    const cachedInfo = await Cache.getItem('federatedInfo');
    token = cachedInfo?.token;
  }
  return parseUser(token);
};
