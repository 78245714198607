import { useState, useEffect } from 'react';

import { getCurrentCredentials } from './auth/fetchAuthSession';
import { Lambda } from 'aws-sdk';

// components
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import RadioButton from '@amzn/meridian/radio-button';
import Row from '@amzn/meridian/row';
import MaterialTable, { Column as MTColumn } from '@material-table/core';

import type { AuthDetails } from './auth/midwayAuth';

type SnopRunRequestProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
};

interface IRunRequest {
  run_id: string;
  lastName: string;
  request_user_id: string;
  request_mode: string;
  base_run_id: string;
  request_datetime: string;
  completion_datetime: string;
  run_status: string;
}

const requestColumns: Array<MTColumn<IRunRequest>> = [
  { title: 'Id', field: 'run_id' },
  { title: 'App Name', field: 'app_name' },
  { title: 'Request User Id', field: 'request_user_id' },
  { title: 'Mode', field: 'request_mode' },
  { title: 'Base Run Id', field: 'base_run_id', type: 'numeric' },
  { title: 'Request DateTime', field: 'request_datetime' },
  { title: 'Completion DateTime', field: 'completion_datetime' },
  { title: 'Status', field: 'run_status' },
];

export const SnopRunRequest = (props: SnopRunRequestProps) => {
  useEffect(() => {
    setUsername(props.userInfo.username);
    setIsDev(props.projectEnv === 'Dev');
    // setIsDev(false);
    props.setPageTitle(`SnOP Run Request${props.projectEnv === 'Dev' ? ' - Beta' : ''}`);
  }, [props]);
  const [username, setUsername] = useState<string>();
  const [isDev, setIsDev] = useState<boolean>();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [latestFullRuns, setLatestFullRuns] = useState<Array<IRunRequest>>();
  const [historyData, setHistoryData] = useState<Array<IRunRequest>>();

  const [applicationValue, setApplicationValue] = useState('outbound');
  const [modeValue, setModeValue] = useState('fullRun');
  const [overrideValue, setOverrideValue] = useState('latestm0');

  var [button0Val, setButton0Val] = useState<number>(0);
  var [button1Val, setButton1Val] = useState<number>(0);
  var [button2Val, setButton2Val] = useState<number>(0);

  var [button0Str, setButton0Str] = useState<string>('N/A');
  var [button1Str, setButton1Str] = useState<string>('N/A');
  var [button2Str, setButton2Str] = useState<string>('N/A');

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitResponse, setSubmitResponse] = useState<string>('');

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const callDelegation = async (userMethodParams: any, successCallBack: Function, errorCallBack: Function) => {
    let auth = await getCurrentCredentials();
    var lambdaClient = new Lambda({
      region: 'us-east-1',
      credentials: auth,
    });
    console.log(
      JSON.stringify({
        function: 'callDelegation',
        auth: auth,
      }),
    );
    console.log(
      JSON.stringify({
        function: 'callDelegation',
        userMethodParams: userMethodParams,
      }),
    );
    var res = lambdaClient.invoke(
      {
        FunctionName: 'arn:aws:lambda:us-east-1:336473673731:function:IRISPythonLambdas-firebolt_delegation4',
        Payload: JSON.stringify(userMethodParams),
      },
      (err, data) => {
        if (data && data.Payload && typeof data.Payload == 'string') {
          console.log('callDelegation callback');
          console.log(data.Payload);
          successCallBack(JSON.parse(data.Payload));
        } else if (err) {
          errorCallBack(true);
        }
      },
    );
    return res;
  };

  const handleSubmit = async () => {
    let params = {
      requestUserId: username,
      isDev: isDev,
      operation: 'SubmitRunRequest',
    };
    if (applicationValue === 'outbound') {
      params['appName'] = 'Fawkes - Outbound';
    } else if (applicationValue === 'lanePlanner') {
      params['appName'] = 'Fawkes - Lane Planner';
      params['requestMode'] = modeValue === 'fullRun' ? 'A - Full Run' : 'B - Override';
      if (modeValue === 'override') {
        switch (overrideValue) {
          case 'latestm0':
            params['baseRunId'] = button0Val;
            break;
          case 'latestm1':
            params['baseRunId'] = button1Val;
            break;
          case 'latestm2':
            params['baseRunId'] = button2Val;
            break;
        }
      }
    }

    try {
      var data = callDelegation(
        params,
        (res) => {
          console.log(res);
          setSubmitResponse(' Success');
          setSubmitted(true);
        },
        (res) => {
          console.log(res);
          setSubmitResponse(' Error');
          setSubmitted(true);
        },
      );
      console.log(JSON.stringify(data));
    } catch (err) {
      console.log('Upload Failed', err);
    }
  };

  useEffect(() => {
    if (submitted) {
      console.log('sleep start');
      sleep(5000).then(
        () => {
          console.log('sleep end');
          setSubmitResponse('');
          setSubmitted(false);
        },
        () => {
          console.error('sleep error');
        },
      );
    }
  }, [submitted]);

  useEffect(() => {
    console.log({
      function: 'useEffect[username, submitted]',
      username: username,
      submitted: submitted,
      isDev: isDev,
    });
    if (username && isDev !== null) {
      console.log({
        function: 'useEffect[username, submitted]',
        event: 'in if',
      });
      try {
        var params1 = {
          operation: 'LatestBaseVersions',
          isDev: isDev,
          limit: 3,
        };
        var data1 = callDelegation(
          params1,
          (res) => {
            if ('errorMessage' in res) {
              console.log('error call LatestBaseVersions');
              console.log(res.errorMessage);
            } else {
              setLatestFullRuns(res);
            }
            try {
              var params2 = {
                operation: 'RecentHistory',
                isDev: isDev,
                limit: 25,
              };
              var data2 = callDelegation(
                params2,
                (res) => {
                  if ('errorMessage' in res) {
                    console.log('error call RecentHistory');
                    console.log(res.errorMessage);
                  } else {
                    setHistoryData(res);
                    setIsDataLoading(false);
                  }
                },
                (res) => {
                  console.log('local error RecentHistory');
                  console.log(res);
                },
              );
              console.log(JSON.stringify(data2));
            } catch (err) {
              setIsDataLoading(false);
              console.log('try error RecentHistory', err);
            }
          },
          (res) => {
            console.log('error call setLatestFullRuns');
            console.log(res);
          },
        );
        console.log(JSON.stringify(data1));
      } catch (err) {
        console.log('try error setLatestFullRuns', err);
      }
    }
  }, [username, submitted, isDev]);

  useEffect(() => {
    if (latestFullRuns) {
      console.debug('useeffect latestFullRuns in if');
      console.debug(latestFullRuns[0]);
      setButton0Val(Number(latestFullRuns[0].run_id));
      setButton0Str(latestFullRuns[0].request_datetime);
      setButton1Val(Number(latestFullRuns[1].run_id));
      setButton1Str(latestFullRuns[1].request_datetime);
      setButton2Val(Number(latestFullRuns[2].run_id));
      setButton2Str(latestFullRuns[2].request_datetime);
    }
  }, [latestFullRuns]);

  return (
    <>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Heading level={5}>Choose Application:</Heading>
          <Column spacing="large">
            <RadioButton
              checked={applicationValue === 'outbound'}
              onChange={setApplicationValue}
              name="application"
              value="outbound"
            >
              Fawkes - Outbound
            </RadioButton>
            <RadioButton
              checked={applicationValue === 'lanePlanner'}
              onChange={setApplicationValue}
              name="application"
              value="lanePlanner"
            >
              Fawkes - Lane Planner
            </RadioButton>
          </Column>
        </Box>
      </Row>

      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Heading level={5}>Choose Mode (applicable only for Fawkes - Lane Planner):</Heading>
          <Column spacing="large">
            <RadioButton
              checked={modeValue === 'fullRun'}
              onChange={setModeValue}
              name="mode"
              value="fullRun"
              disabled={applicationValue === 'lanePlanner' ? false : true}
            >
              A - Full Run
            </RadioButton>
            <RadioButton
              checked={modeValue === 'override'}
              onChange={setModeValue}
              name="mode"
              value="override"
              disabled={applicationValue === 'lanePlanner' ? false : true}
            >
              B - Override
            </RadioButton>
          </Column>
        </Box>
      </Row>

      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Heading level={5}>Choose Base Version (applicable only for B - Override):</Heading>
          <Column spacing="large">
            <RadioButton
              checked={overrideValue === 'latestm0'}
              onChange={setOverrideValue}
              name="mode"
              value="latestm0"
              disabled={applicationValue === 'lanePlanner' && modeValue === 'override' ? false : true}
            >
              {button0Val !== 0 && button0Str + ' [' + button0Val.toString() + ']'}
            </RadioButton>
            <RadioButton
              checked={overrideValue === 'latestm1'}
              onChange={setOverrideValue}
              name="mode"
              value="latestm1"
              disabled={applicationValue === 'lanePlanner' && modeValue === 'override' ? false : true}
            >
              {button1Val !== 0 && button1Str + ' [' + button1Val.toString() + ']'}
            </RadioButton>
            <RadioButton
              checked={overrideValue === 'latestm2'}
              onChange={setOverrideValue}
              name="mode"
              value="latestm2"
              disabled={applicationValue === 'lanePlanner' && modeValue === 'override' ? false : true}
            >
              {button2Val !== 0 && button2Str + ' [' + button2Val.toString() + ']'}
            </RadioButton>
          </Column>
        </Box>
      </Row>

      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Button onClick={handleSubmit}>Submit</Button>
          {submitted ? submitResponse : ''}
        </Box>
      </Row>

      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          {!isDataLoading ? (
            historyData ? (
              <MaterialTable
                style={{ zIndex: 1 }}
                columns={requestColumns}
                data={historyData}
                title="Recently Submitted Requests"
                options={{
                  search: false,
                  paging: false,
                  pageSize: 25,
                  emptyRowsWhenPaging: false,
                  headerStyle: { backgroundColor: '#11ffee00', position: 'sticky', top: 0, fontWeight: 'bold' },
                }}
              />
            ) : null
          ) : (
            <Loader />
          )}
        </Box>
      </Row>
    </>
  );
};
