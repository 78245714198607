// eslint-disable-next-line
import React, { Component } from 'react';

class UnauthorizedUser extends Component {
  render() {
    return <div id="bindlediv">amzn1.bindle.resource.mj6vairumqn7t5jznnrq</div>;
  }
}

export default UnauthorizedUser;
