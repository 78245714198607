/* tslint:disable */
/* eslint-disable */

export const onCreateFireBoltStatusInfoAppSyncJobID = /* GraphQL */ `
  subscription OnCreateFireBoltStatusInfoAppSyncJobID($id: ID!) {
    onCreateFireBoltStatusInfoAppSyncJobID(id: $id) {
      id
      results
    }
  }
`;

export const onUpdateFireBoltStatus = /* GraphQL */ `
  subscription OnUpdateFireBoltStatus {
    onUpdateFireBoltStatus {
      id
      attribkey
      status
    }
  }
`;

export const onUpdateFireBoltStatusInfo = /* GraphQL */ `
  subscription onUpdateFireBoltStatusInfo {
    onUpdateFireBoltStatusInfo {
      id
      status
    }
  }
`;

export const listFireBoltStatusInfos = /* GraphQL */ `
  query ListFireBoltStatusInfos(
    $id: ID
    $filter: ModelFireBoltStatusInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFireBoltStatusInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
      }
      nextToken
    }
  }
`;

export const createFireBoltStatus = /* GraphQL */ `
  mutation CreateFireBoltStatus($input: CreateFireBoltStatusInput!, $condition: ModelFireBoltStatusConditionInput) {
    createFireBoltStatus(input: $input, condition: $condition) {
      id
      attribkey
      status
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteFireBoltStatus = /* GraphQL */ `
  subscription OnDeleteFireBoltStatus {
    onDeleteFireBoltStatus {
      id
      attribkey
      status
    }
  }
`;

export const batchGetFireBoltStatus = /* GraphQL */ `
  query BatchGetFireBoltStatus($ids: [ID]) {
    batchGetFireBoltStatus(ids: $ids) {
      id
      status
    }
  }
`;

export const getFireBoltStatusInfo = /* GraphQL */ `
  query GetFireBoltStatusInfo($id: ID!) {
    getFireBoltStatusInfo(id: $id) {
      id
      status
    }
  }
`;
