// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';

import { DynamoDB } from 'aws-sdk';

import AppLayout from '@amzn/meridian/app-layout';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';

// Masthead
import Masthead, {
  MastheadTitle,
  // MastheadLink,
  //MastheadMenuButton,
} from '@amzn/meridian/masthead';
//import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Loader from '@amzn/meridian/loader';
import Blink from './Blink';

import { Routes, Route } from 'react-router-dom';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { generateClient } from 'aws-amplify/api';

import { getCurrentCredentials } from './auth/fetchAuthSession';

import { ProjectScreensAccess, DropdownAttributes, Option, DimOptions, ProjectRefreshStatus } from './Types';
import { authenticateAmplify } from './auth/midwayAuth';
import type { AuthDetails } from './auth/midwayAuth';
import SidebarLayout from './SidebarLayout';
import { HomeView } from './HomeView';
import { BatchUploadDataView } from './BatchUploadDataView';
import { ManageCalculationsView } from './ManageCalculationsView';
import { ReviewDataView } from './ReviewDataView';
import { SnopRunRequest } from './SnopRunRequest';
import { SnopPublish } from './SnopPublish';

import { SubmitRunRequest } from './SubmitRunRequest';
import UnauthorizedUser from './error/UnauthorizedUser';
import { s3Region, emptyAuthDetails } from './Constants';

import { batchGetFireBoltStatus, onUpdateFireBoltStatusInfo } from '../graphql/custom';

import logo from '../img/amazon-logo-a-smile-black.png';

import Column from '@amzn/meridian/column';
import { getDropdownAttributes } from './CommonFunctions';

//import { AppState } from '@aws-amplify/core';

//const projectEnvironment = window.location.hostname.includes('dev-dsk') || window.location.hostname.includes('beta') ? 'Dev' : '';

const graphQLClient = generateClient();

const initDDB = async () => {
  var ddb = new DynamoDB({ apiVersion: '2012-08-10' });
  ddb.config.update({
    region: s3Region,
    credentials: await getCurrentCredentials(),
  });
  return ddb;
};

//var projectHrefList: Object = {};

export interface AppState {
  isLoggedIn: boolean;
  userInfo: AuthDetails;
  projectHrefList: ProjectScreensAccess;
  projectEnvironment: string;
  isDropDownsLoaded: boolean;
  dropdownAttributes: DropdownAttributes;
  selectedMetrics: Option[];
  displaySelecteddimList: DimOptions;
  projectRefreshStatus: ProjectRefreshStatus;
  projectRefreshStatusInitiated: boolean;
  projectName: String;
}

export const FireBoltApplicationLayout = () => {
  const [currentPage, setCurrentPage] = useState<string>('Home');
  const [projectName, setProjectName] = useState<string>();
  const projectNameRef = useRef<string | null>(null);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isDropDownsLoaded, setIsDropDownsLoaded] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<AuthDetails>(emptyAuthDetails);

  const [projectList, setProjectList] = useState<string[]>([]);
  const [hrefList, sethrefList] = useState<string[]>([]);
  const [projectHrefList] = useState<ProjectScreensAccess>({});
  const [dropdownAttributes, setDropdownAttributes] = React.useState<DropdownAttributes>();
  const [selectedMetrics, setSelectedMetrics] = useState<Option[]>([]);
  const [displaySelecteddimList, setDisplaySelecteddimList] = React.useState<DimOptions>({});
  const [selectedUploadTimes, setSelectedUploadTimes] = useState<Option[]>([]);

  const [projectRefreshStatus, setProjectRefreshStatus] = useState<ProjectRefreshStatus>({});
  const [projectRefreshStatusInitiated, setProjectRefreshStatusInitiated] = useState<Boolean>(false);

  const [showRefreshInProgress, setShowRefreshInProgress] = useState<boolean>();

  const [projectEnvironment] = useState<string>(() => {
    if (
      window.location.hostname.includes('dev-dsk') ||
      window.location.hostname.includes('beta.irisfirebolt') ||
      window.location.hostname.includes('dev.irisfirebolt')
    ) {
      return 'Dev';
    }
    return ''; // This will be treated as 'Prod'
  });

  const getEnvironmentSuffix = () => (projectEnvironment === 'Dev' ? '-Dev' : '');

  document.title = 'GSF Firebolt' + getEnvironmentSuffix();

  useEffect(() => {
    const authUser = async () => {
      try {
        const loggedInUser: AuthDetails = await authenticateAmplify(projectEnvironment);
        if (loggedInUser.username !== '') {
          setIsLoggedIn(true);
          setUserInfo(loggedInUser);
        } else {
          console.warn('Authentication returned empty username');
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    };
    authUser();
  }, [projectEnvironment]);

  useEffect(() => {
    const initUser = async () => {
      global.AWS.config.region = s3Region;

      const ddb = await initDDB();
      var params = {
        TableName: 'FireBoltConfig-dev',
        Key: {
          ProjectName: { S: 'ALL' + getEnvironmentSuffix() },
          SettingParam: { S: 'Projects' },
        },
        ProjectionExpression: 'ProjectList',
        ConsistentRead: true,
      };
      const data = await ddb.getItem(params).promise();

      const projList = data.Item ? data.Item.ProjectList.SS : [];
      const filteredProjectList = projList!.filter((value) => userInfo.allowedProjects.includes(value));

      setProjectList(filteredProjectList);

      //projChange(loggedInUser.allowedProjects[0]); //should I remove it?

      try {
        const responseProjectRefreshStatus: any = await graphQLClient.graphql({
          query: batchGetFireBoltStatus,
          variables: {
            ids: filteredProjectList.map((e) => e + '-' + projectEnvironment + '-RefreshInProgressStatus'),
          },
          authMode: 'userPool',
        });

        var tmpProjRefreshStatus = {};
        responseProjectRefreshStatus.data.batchGetFireBoltStatus.map((e) =>
          e !== null ? (tmpProjRefreshStatus[e.id] = e.status) : null,
        );

        var tmpProjRefreshStatusAllProjects = {};

        filteredProjectList.map(
          (e) =>
            (tmpProjRefreshStatusAllProjects[e] = tmpProjRefreshStatus[
              e + getEnvironmentSuffix() + '-RefreshInProgressStatus'
            ]
              ? tmpProjRefreshStatus[e + '-' + projectEnvironment + '-RefreshInProgressStatus']
              : false),
        );

        setProjectRefreshStatus(tmpProjRefreshStatusAllProjects);
        setProjectRefreshStatusInitiated(true);
      } catch (err) {
        console.log('error fetching project status...' + JSON.stringify(err));
      }
    };
    if (isLoggedIn) initUser();

    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    const subscribeToRefreshStatus = async () => {
      (
        graphQLClient.graphql({
          ...graphqlOperation(onUpdateFireBoltStatusInfo),
          authMode: 'userPool',
        }) as any
      ).subscribe({
        next: (changeStatus) => {
          const changedStatusProj = changeStatus.value.data.onUpdateFireBoltStatusInfo;
          const updatedKey: String = changedStatusProj.id;

          if (updatedKey.endsWith('-RefreshInProgressStatus') && projectRefreshStatusInitiated) {
            var tmpProjRefreshStatus = projectRefreshStatus;
            tmpProjRefreshStatus[updatedKey.replace(getEnvironmentSuffix() + '-RefreshInProgressStatus', '')] =
              changedStatusProj.status;
            setProjectRefreshStatus(tmpProjRefreshStatus);
            console.log(tmpProjRefreshStatus);

            if (projectNameRef.current === updatedKey.replace(getEnvironmentSuffix() + '-RefreshInProgressStatus', ''))
              setShowRefreshInProgress(tmpProjRefreshStatus[String(projectNameRef.current)]);

            //console.log(projectRefreshStatus)
            //console.log(projectName + '-' + projectEnvironment)
          }
        },
      });
    };
    if (isLoggedIn) subscribeToRefreshStatus();
    // eslint-disable-next-line
  }, [projectRefreshStatusInitiated]);

  const projChange = async (value: string) => {
    if (!projectHrefList.hasOwnProperty(value)) {
      let projectionExpression: string;
      if (userInfo.adminProjects.includes(value)) projectionExpression = 'hrefAdmin';
      else if (userInfo.editProjects.includes(value)) projectionExpression = 'hrefEdit';
      else projectionExpression = 'hrefUser';

      var params = {
        TableName: 'FireBoltConfig-dev',
        Key: {
          ProjectName: { S: value + getEnvironmentSuffix() },
          SettingParam: { S: 'ProjectScreens' },
        },
        ProjectionExpression: projectionExpression,
        ConsistentRead: true,
      };
      const ddb = await initDDB();
      const data = await ddb.getItem(params).promise();

      let newHrefs: ProjectScreensAccess = {};
      newHrefs[value] = { href: [], access: '' };
      newHrefs[value].href = data.Item ? data.Item[projectionExpression].SS! : [];
      newHrefs[value].access = projectionExpression.replace('href', '');
      projectHrefList[value] = newHrefs[value];
    }
    sethrefList(projectHrefList[value].href);
    setProjectName(value);
    setShowRefreshInProgress(projectRefreshStatus[value]);
    setIsDropDownsLoaded(false);
    setSelectedMetrics([]);
    setDisplaySelecteddimList({});
    setSelectedUploadTimes([]);
    projectNameRef.current = value;

    const dropdownAttributes = await getDropdownAttributes(userInfo.username, value, projectEnvironment);
    setDropdownAttributes(dropdownAttributes['DropdownAttributes']);
    setDisplaySelecteddimList(dropdownAttributes['DisplaySelecteddimList']);
    setIsDropDownsLoaded(true);
  };

  const Footer = () => (
    <Row alignmentVertical="center" alignmentHorizontal="center">
      <Text type="h100" color="secondary">
        brought to you by gsf-iris
      </Text>
    </Row>
  );

  if (isLoggedIn) {
    return (
      <React.StrictMode>
        <AppLayout headerComponent={Masthead} sidebarComponent={SidebarLayout} footerComponent={Footer}>
          <Masthead>
            <MastheadTitle href="/">
              <Row>
                <img src={logo} alt="" height="40px" width="40px" />
                <Text type="h200">{currentPage}</Text>
              </Row>
            </MastheadTitle>

            <Column alignmentVertical="top">
              {showRefreshInProgress ? (
                <Blink color="red" text="Recalc In Progress .." fontSize="20">
                  Recalc In Progress ..
                </Blink>
              ) : null}
            </Column>

            <Column>
              <Row>
                <Text type="h200">
                  Welcome, {userInfo.firstName}
                  {'\n'}
                  <br />
                  Access: {projectHrefList[projectName!]?.access}
                </Text>
              </Row>
            </Column>
            <Column>
              <Select value={projectName} placeholder="Select Project ..." onChange={projChange} width={300}>
                {projectList.map((projectItem) => (
                  <SelectOption key={projectItem} value={projectItem} label={projectItem} />
                ))}
              </Select>
            </Column>
          </Masthead>
          <SidebarLayout displayedLinks={hrefList} />

          {projectName !== undefined && projectEnvironment !== undefined ? (
            <Routes>
              <Route
                path="/"
                element={
                  <HomeView
                    setPageTitle={setCurrentPage}
                    userInfo={userInfo}
                    projectName={projectName}
                    projectEnv={projectEnvironment}
                  />
                }
              />
              {hrefList.includes('/batchuploaddata') ? (
                <Route
                  path="/batchuploaddata"
                  element={
                    <BatchUploadDataView
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                      dataType="InputData"
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/batchuploadoverridedata') ? (
                <Route
                  path="/batchuploadoverridedata"
                  element={
                    <BatchUploadDataView
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                      dataType="OverrideData"
                      recalcInProgress={showRefreshInProgress}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/managecalculations') ? (
                <Route
                  path="/managecalculations"
                  element={
                    <ManageCalculationsView
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                      projectUserAccess={projectHrefList[projectName]?.access}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/reviewinputdata') ? (
                <Route
                  path="/reviewinputdata"
                  element={
                    <ReviewDataView
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                      dataType="InputData"
                      dropDownStatus={{
                        IsLoaded: isDropDownsLoaded,
                        SetIsLoaded: setIsDropDownsLoaded,
                        Attributes: dropdownAttributes,
                        SetAttributes: setDropdownAttributes,
                        SelectedMetrics: selectedMetrics,
                        SetSelectedMetrics: setSelectedMetrics,
                        DisplaySelecteddimList: displaySelecteddimList,
                        SetDisplaySelecteddimList: setDisplaySelecteddimList,
                        SelectedUploadTimes: selectedUploadTimes,
                        SetSelectedUploadTimes: setSelectedUploadTimes,
                      }}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/reviewresultsandeditoverrides') ? (
                <Route
                  path="/reviewresultsandeditoverrides"
                  element={
                    <ReviewDataView
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                      dataType="ResultsAndOverride"
                      recalcInProgress={showRefreshInProgress}
                      dropDownStatus={{
                        IsLoaded: isDropDownsLoaded,
                        SetIsLoaded: setIsDropDownsLoaded,
                        Attributes: dropdownAttributes,
                        SetAttributes: setDropdownAttributes,
                        SelectedMetrics: selectedMetrics,
                        SetSelectedMetrics: setSelectedMetrics,
                        DisplaySelecteddimList: displaySelecteddimList,
                        SetDisplaySelecteddimList: setDisplaySelecteddimList,
                        SelectedUploadTimes: selectedUploadTimes,
                        SetSelectedUploadTimes: setSelectedUploadTimes,
                      }}
                      projectUserAccess={projectHrefList[projectName]?.access}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/submitrunrequest') ? (
                <Route
                  path="/submitrunrequest"
                  element={
                    <SubmitRunRequest
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/snoprunrequest') ? (
                <Route
                  path="/snoprunrequest"
                  element={
                    <SnopRunRequest
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                    />
                  }
                />
              ) : null}
              {hrefList.includes('/snoppublish') ? (
                <Route
                  path="/snoppublish"
                  element={
                    <SnopPublish
                      setPageTitle={setCurrentPage}
                      userInfo={userInfo}
                      projectName={projectName}
                      projectEnv={projectEnvironment}
                    />
                  }
                />
              ) : null}
              <Route path="/error/403.html" element={<UnauthorizedUser />} />
            </Routes>
          ) : null}

          <Footer />
        </AppLayout>
      </React.StrictMode>
    );
  } else {
    return <Loader />;
  }
};

export default FireBoltApplicationLayout;
