type QueryParameters = { [key: string]: string }; // tslint:disable-line:readonly-keyword

/**
 * Copy of a package we don't want to depend on:
 * https://code.amazon.com/packages/MidwayIdentityCredentialProvider/blobs/mainline/--/src/token-retriever.ts
 * It encapsulates the Midway OpenId token retrieval. If the session has expired, then a page redirection is executed.
 */
export class TokenRetriever {
  static getTokenOrRedirect(): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', TokenRetriever.buildSSOUrl());
      xhr.withCredentials = true;
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          window.location.href = TokenRetriever.buildRedirectUrl();
        }
      };
      // closes the promise in the case of an error
      xhr.onerror = reject;
      xhr.send();
    });
  }

  private static buildRedirectUrl(): string {
    const queryParams: QueryParameters = {
      client_id: encodeURIComponent(window.location.host),
      redirect_uri: encodeURIComponent(window.location.href),
      response_type: 'id_token',
      scope: 'openid',
      nonce: TokenRetriever.generateNonce(),
    };

    return `https://midway-auth.amazon.com/login?next=/SSO/redirect${encodeURIComponent(TokenRetriever.buildQuery(queryParams))}`;
  }

  private static buildSSOUrl(): string {
    const queryParams: QueryParameters = {
      response_type: 'id_token',
      client_id: encodeURIComponent(window.location.host),
      redirect_uri: encodeURIComponent(window.location.href),
      scope: 'openid',
      nonce: TokenRetriever.generateNonce(),
    };

    return `https://midway-auth.amazon.com/SSO${TokenRetriever.buildQuery(queryParams)}`;
  }

  private static buildQuery(parameters: QueryParameters): string {
    return Object.keys(parameters).reduce((accumulator, key) => `${accumulator + key}=${parameters[key]}&`, '?');
  }

  private static generateNonce(): string {
    let nonce = '';
    const characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 64; i += 1) {
      nonce += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
    }
    return nonce;
  }
}
