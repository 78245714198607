import { ResourcesConfig } from 'aws-amplify';
import { s3Bucket, s3Region } from './Constants';

export const storageConfig: ResourcesConfig['Storage'] = {
  S3: {
    region: s3Region,
    bucket: s3Bucket,
    // Add other S3 configurations as needed
  },
};
