import { uploadData, getUrl, list } from 'aws-amplify/storage';

type S3ListItem = {
  path: string;
  lastModified?: Date;
  size?: number;
  eTag?: string;
};

export class S3Helper {
  private static downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  static async put(filePath: string, file: any, options: { contentType: string }) {
    try {
      let data: Blob | Buffer;

      if (file instanceof Blob) {
        // If it's already a Blob, we can use it directly
        data = file;
      } else if (typeof file.arrayBuffer === 'function') {
        // If it has an arrayBuffer method (like File objects), convert to Blob
        const buffer = await file.arrayBuffer();
        data = new Blob([buffer], { type: options.contentType });
      } else if (Buffer.isBuffer(file)) {
        // If it's a Buffer, we can use it directly
        data = file;
      } else {
        throw new Error('Unsupported file type');
      }

      const result = await uploadData({
        path: filePath,
        data: data,
        options: {
          contentType: options.contentType,
        },
      }).result;

      return result.path;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  }

  static async get(filePath: string, options?: { download?: boolean }) {
    try {
      const { url } = await getUrl({ path: filePath });
      if (options?.download) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        return { Body: blob };
      } else {
        return { url };
      }
    } catch (error) {
      console.error('Error getting file from S3:', error);
      throw error;
    }
  }

  static async list(path: string): Promise<S3ListItem[]> {
    try {
      const result = await list({ path });
      return result.items.map((item) => ({
        path: item.path,
        lastModified: item.lastModified ? new Date(item.lastModified) : undefined,
        size: item.size,
        eTag: item.eTag,
      }));
    } catch (error) {
      console.error('Error listing S3 files:', error);
      throw error;
    }
  }

  static async downloadFromS3(fileKey: string, fileName: string) {
    try {
      const result = await this.get(fileKey, { download: true });
      if (result.Body instanceof Blob) {
        this.downloadBlob(result.Body, fileName);
      } else {
        throw new Error('Unexpected result type from get');
      }
    } catch (error) {
      console.error('Error downloading file from S3:', error);
      throw error;
    }
  }

  static async waitForS3File(filePath: string, waitMS: number, attempts: number) {
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    for (let attempt = 0; attempt < attempts; attempt++) {
      const items = await this.list(filePath);
      console.log(items.length);
      if (items.length > 0) return true;
      await delay(waitMS);
    }
    return false;
  }
}
