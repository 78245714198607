// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { DynamoDB } from 'aws-sdk';
import Glue from 'aws-sdk/clients/glue';

import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';

import MaterialTable from '@material-table/core';
import { API } from './API';
import { getCurrentCredentials } from './auth/fetchAuthSession';

import { s3Region } from './Constants';
import { JobRunResponse } from './Types';

import type { AuthDetails } from './auth/midwayAuth';

import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Blink from './Blink';

const initDDB = async () => {
  var ddb = new DynamoDB({ apiVersion: '2012-08-10' });
  ddb.config.update({
    region: s3Region,
    credentials: await getCurrentCredentials(),
  });
  return ddb;
};

const initGlue = async () => {
  var glue = new Glue({
    apiVersion: '2017-03-31',
    region: s3Region,
    credentials: await getCurrentCredentials(),
  });
  return glue;
};

type HomeProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
};

export const SubmitRunRequest = (props: HomeProps) => {
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isSelectionChanged, setIsSelectionChanged] = useState<boolean>(false);
  const [fireboltTableHeaders, setFireboltLogTableHeaders] = useState<Object[]>([]);
  const [fireboltTableData, setFireboltLogTableData] = useState<Object[]>([]);
  const [requestName, setRequestName] = useState('');
  const [submittedRequestResponse, setSubmittedRequestResponse] = useState('');
  const [editCustomMsg, setEditCustomMsg] = useState('Click The Pen Icon To Edit...');
  const [requestSubmittingStatus, setRequestSubmittingStatus] = useState(false);
  const [submittedRequestType, setSubmittedRequestType] = useState('');
  const [jobRunResponse, setJobRunResponse] = useState<JobRunResponse>({});

  const tableHeight = ((window.innerHeight - 64 - 64 - 52 - 1) / window.innerHeight) * 70;

  useEffect(() => {
    props.setPageTitle(`Submit Run Request${props.projectEnv === 'Dev' ? ' - Beta' : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getConstraints = async () => {
      const ddb = await initDDB();
      var params = {
        TableName: 'FireBoltConfig-dev',
        Key: {
          ProjectName: { S: props.projectName + (props.projectEnv === 'Dev' ? '-Dev' : '') },
          SettingParam: { S: 'Run-Request-Constraints' },
        },
        ProjectionExpression: 'ConstraintList',
        ConsistentRead: true,
      };
      //console.log(params)
      const data = await ddb.getItem(params).promise();

      const resultList = data.Item?.ConstraintList.L!.map((e, rowId) => {
        return {
          Row_ID: rowId,
          ...(e.M!['Constraint Type'] && { Constraint_Type: e.M!['Constraint Type'].S }),
          ...(e.M!['Constraint Name'] && { Constraint_Name: e.M!['Constraint Name'].S }),
          ...(e.M!['Constraint Name'] && { Override_w_SnOP: false }),

          ...(e.M!['Job Type'] && { Job_Type: e.M!['Job Type'].S }),
          ...(e.M!['Job Name'] && { Job_Name: e.M!['Job Name'].S }),
          ...(e.M!['Job Name'] && { Run: false }),
        };
      });

      const tmpSubmittedRequestType = resultList![0].Job_Name === undefined ? 'Constraint' : 'Job';
      setSubmittedRequestType(tmpSubmittedRequestType);

      const dataHeader = Object.keys(resultList![0]).map((key) => {
        return {
          title: key.replace(/_/g, ' '),
          field: key,
          ...(['Row_ID', 'Actions'].includes(key) && { hidden: true }),
          ...(!['Override_w_SnOP', 'Run'].includes(key) && { editable: 'never' }),
          ...(['Override_w_SnOP', 'Run'].includes(key) && {
            type: 'boolean',
            editComponent: (props) => {
              return (
                <input
                  type="checkbox"
                  checked={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.checked);
                    setIsSelectionChanged(true);
                    setEditCustomMsg('Click the check mark on top right to finish editing...');
                  }}
                />
              );
            },
            render: (rowdata: { Override_w_SnOP: any; Run: any }) => (
              <input
                type="checkbox"
                checked={tmpSubmittedRequestType === 'Constraint' ? rowdata.Override_w_SnOP : rowdata.Run}
                readOnly
              />
            ),
          }),
        };
      });

      setFireboltLogTableData(resultList!);
      setFireboltLogTableHeaders(dataHeader);
      setIsDataLoaded(true);
    };

    getConstraints();
  }, [props.projectEnv, props.projectName]);

  const handleRunRequestSubmit = async () => {
    const tmpJobRunResponse = {};
    setRequestSubmittingStatus(true);
    const selectionArray = fireboltTableData.map((e) => {
      const tmpObj = {};
      const el: any = e;
      if (submittedRequestType === 'Constraint')
        tmpObj[el.Constraint_Type + '~' + el.Constraint_Name] = el.Override_w_SnOP;
      else if (submittedRequestType === 'Job') {
        tmpObj[el.Job_Type + '~' + el.Job_Name] = el.Run;
      }
      return tmpObj;
    });

    if (submittedRequestType === 'Job') {
      const glue = await initGlue();
      await Promise.all(
        fireboltTableData.map(async (e) => {
          const el: any = e;
          if (el.Job_Type === 'ETL' && el.Run) {
            try {
              var params = {
                JobName: el.Job_Name,
              };
              glue.startJobRun(params, function (err, data) {
                if (err) {
                  console.log(err, err.stack);
                  tmpJobRunResponse[el.Job_Name] = JSON.stringify(err);
                } else tmpJobRunResponse[el.Job_Name] = data.JobRunId;
              });
            } catch (err: any) {
              tmpJobRunResponse[el.Job_Name] = JSON.stringify(err.name);
            }
          }
        }),
      );
      setJobRunResponse(tmpJobRunResponse);
      console.log(tmpJobRunResponse);
    }

    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: 'submitRunRequest_Override_w_SnOP',
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        userId: props.userInfo.username,
        Override_w_SnOP: JSON.stringify([...selectionArray, tmpJobRunResponse]),
        requestName: requestName,
      },
    };
    console.log(apiCallParam);
    const data = await API.get('FireboltWebsiteAPIs', '/uploadinputdatafiles', apiCallParam);
    console.log(data);
    setSubmittedRequestResponse(data);
    setRequestSubmittingStatus(false);
    return true;
  };

  return isDataLoaded ? (
    <>
      <Row width="100%" widths="fill" height="100%">
        <Box type="outline" spacingInset="medium" height="100%">
          <Row width="100%" widths="fill" height="85%" alignmentVertical="top">
            <Column spacing="medium">
              <Heading level={5}>Select Run Options:</Heading>
              <MaterialTable
                style={{ zIndex: 1 }}
                columns={fireboltTableHeaders}
                data={fireboltTableData}
                title={
                  <Blink color="blue" text={editCustomMsg} fontSize="20">
                    {editCustomMsg}
                  </Blink>
                }
                editable={{
                  onBulkUpdate: (selectedRows) =>
                    new Promise<void>((resolve, reject) => {
                      const rows = Object.values(selectedRows);
                      const updatedRows = [...fireboltTableData];
                      let index;
                      // eslint-disable-next-line
                      rows.map((e) => {
                        index = (e.oldData as any).tableData.id;
                        updatedRows[index] = e.newData;
                      });
                      setTimeout(() => {
                        setFireboltLogTableData(updatedRows);
                        setIsSelectionChanged(false);
                        setEditCustomMsg('Click The Pen Icon To Edit...');
                        resolve();
                      }, 2000);
                    }),
                }}
                options={{
                  paging: true,
                  pageSize: 5,
                  search: false,
                  actionsColumnIndex: -1,
                  maxBodyHeight: `${tableHeight}vh`,
                  minBodyHeight: `${tableHeight}vh`,
                  filtering: true,
                  headerStyle: { backgroundColor: '#11ffee00', position: 'sticky', top: 0, fontWeight: 'bold' },
                }}
              />
            </Column>
          </Row>
          <Row width="100%" widths="fill" height="5%" alignmentVertical="top">
            <Column spacing="medium" alignmentHorizontal="start">
              <Row>
                <Column>
                  <Text>Enter Request Name:</Text>
                </Column>
                <Column width="300px">
                  <Input
                    value={requestName}
                    onChange={setRequestName}
                    placeholder="Enter Request Name ...."
                    size="small"
                    width="300px"
                  />
                </Column>
              </Row>
            </Column>
            <Column spacing="medium" alignmentHorizontal="end">
              <Button onClick={handleRunRequestSubmit} disabled={requestName.length === 0 || isSelectionChanged}>
                {' '}
                Submit{' '}
              </Button>
            </Column>
          </Row>
          <Row width="100%" widths="fill" height="5%" alignmentVertical="top">
            {submittedRequestResponse.length > 0 ? (
              <Text>{submittedRequestResponse}</Text>
            ) : requestSubmittingStatus ? (
              <Loader />
            ) : null}
          </Row>
          <Row width="100%" widths="fill" height="5%" alignmentVertical="top">
            {Object.keys(jobRunResponse).length > 0 ? (
              <Text>
                {' '}
                <pre>{JSON.stringify(jobRunResponse, null)}</pre>{' '}
              </Text>
            ) : requestSubmittingStatus ? (
              <Loader />
            ) : null}
          </Row>
        </Box>
      </Row>
    </>
  ) : (
    <Loader />
  );
};
