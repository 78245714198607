import { refreshMidway } from './midwayTokenRetriever';
import { getCurrentUser, signInWithRedirect, fetchUserAttributes } from 'aws-amplify/auth';
import { emptyAuthDetails } from '../Constants';

function filterAndModifyProjects(projectList: string[], projectEnv: string) {
  const filterKeyword = projectEnv === 'Dev' ? 'Beta ~ ' : 'Prod ~ ';
  return projectList
    .filter((project) => project.startsWith(filterKeyword))
    .map((project) => project.replace(/^Beta ~ |^Prod ~ /, ''));
}

export interface AuthDetails {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  allowedProjects: string[];
  editProjects: string[];
  adminProjects: string[];
}

export const authenticateAmplify = async (projectEnv: string = ''): Promise<AuthDetails> => {
  const environment = projectEnv === 'Dev' ? 'Dev' : 'Prod';
  console.log(`Authenticating for environment: '${environment}'`);

  try {
    const credentials = await refreshMidway();

    if (credentials) {
      try {
        const currentUser = await getCurrentUser();
        console.log(`Current User: ${currentUser.username}`);
        const attributes = await fetchUserAttributes();

        const { identities } = attributes;
        let userId: string;

        if (identities) {
          const parsedIdentities = JSON.parse(identities);
          userId = parsedIdentities[0]?.userId;
          if (!userId) {
            throw new Error('User ID not found in identities');
          }
        } else {
          throw new Error('Identities not found in user attributes');
        }

        const parseAttributes = (attributes: any, targetString: string) => {
          const attributeValue = attributes[`custom:${targetString}`];
          if (!attributeValue) {
            return [];
          }
          const attributesAsList = attributeValue
            .slice(1, -1)
            .split(', ')
            .map((item: string) => decodeURIComponent(item.replace(/\+/g, ' ')));
          return filterAndModifyProjects(attributesAsList, environment);
        };

        const projectsByGroup = parseAttributes(attributes, 'projectsByGroup');
        const projectEditorByGroup = parseAttributes(attributes, 'projectEditorByGroup');
        const projectAdminByGroup = parseAttributes(attributes, 'projectAdminByGroup');

        console.log('Projects by Group:', projectsByGroup);
        console.log('Project Editor by Group:', projectEditorByGroup);
        console.log('Project Admin by Group:', projectAdminByGroup);

        let userInfo: AuthDetails = {
          username: userId,
          firstName: attributes.given_name || '',
          lastName: attributes.family_name || '',
          email: attributes.email || '',
          allowedProjects: Array.from(new Set([...projectsByGroup, ...projectAdminByGroup, ...projectEditorByGroup])),
          editProjects: Array.from(new Set([...projectEditorByGroup, ...projectAdminByGroup])),
          adminProjects: Array.from(new Set(projectAdminByGroup)),
        };

        return userInfo;
      } catch (err) {
        console.error('Error during authentication:', err);
        if (err instanceof Error && err.name === 'UserUnAuthenticatedException') {
          console.log('User is not authenticated. Initiating sign-in process...');
          await signInWithRedirect({ provider: { custom: 'MidwayOIDC' } });
        } else {
          // For other types of errors, we might want to handle them differently
          throw err;
        }
      }
    }
  } catch (err) {
    console.error(`Error occurred during authentication: ${err}`);
  }

  return emptyAuthDetails;
};
