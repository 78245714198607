import React, { useState, useEffect, ReactNode } from 'react';

interface BlinkProps {
  color: string;
  text?: string;
  fontSize: string;
  children?: ReactNode;
}

const Blink: React.FC<BlinkProps> = ({ color, text, fontSize, children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500); // Change 500 to adjust blink speed (in milliseconds)

    return () => clearInterval(intervalId);
  }, []);

  const style: React.CSSProperties = {
    color: color,
    fontSize: fontSize,
    visibility: isVisible ? 'visible' : 'hidden',
  };

  return <span style={style}>{children || text}</span>;
};

export default Blink;
