export const s3Bucket = 'gsf-firebolt-app-files212421-dev';
export const s3Region = 'us-east-1';
export const emptyAuthDetails = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  allowedProjects: [],
  editProjects: [],
  adminProjects: [],
};
