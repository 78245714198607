import { fetchAuthSession } from 'aws-amplify/auth';

export async function getCurrentCredentials() {
  try {
    const { credentials } = await fetchAuthSession();
    return credentials;
  } catch (err) {
    console.error('Error fetching credentials:', err);
    return null;
  }
}
