import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { storageConfig } from './components/AmplifyConfiguration';

import FireBoltApplicationLayout from './components/FireBoltApplicationLayout';
import awsExports from './aws-exports';

import './global-styles.scss';

if (window.location.hostname.includes('dev-dsk')) {
  awsExports.oauth.redirectSignIn = `https://${window.location.hostname}:3000/`;
  awsExports.oauth.redirectSignOut = `https://${window.location.hostname}:3000/`;
} else if (window.location.hostname.includes('beta')) {
  awsExports.oauth.redirectSignIn = 'https://beta.irisfirebolt.gsf.amazon.dev/';
  awsExports.oauth.redirectSignOut = 'https://beta.irisfirebolt.gsf.amazon.dev/';
} else if (window.location.hostname.includes('dev.irisfirebolt')) {
  awsExports.oauth.redirectSignIn = 'https://dev.irisfirebolt.gsf.amazon.dev/';
  awsExports.oauth.redirectSignOut = 'https://dev.irisfirebolt.gsf.amazon.dev/';
} else {
  awsExports.oauth.redirectSignIn = 'https://irisfirebolt.gsf.amazon.dev/';
  awsExports.oauth.redirectSignOut = 'https://irisfirebolt.gsf.amazon.dev/';
}

// Configure Amplify
const amplifyConfig = {
  ...awsExports,
  ...storageConfig,
  // Add any other configurations here as needed
};

Amplify.configure(amplifyConfig);

export default function loadLocalizedWebApp() {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <FireBoltApplicationLayout />
      </BrowserRouter>
    </React.StrictMode>,
  );
}

loadLocalizedWebApp();
