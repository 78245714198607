// eslint-disable-next-line
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//import Row from '@amzn/meridian/row';
//import Text from '@amzn/meridian/text';
//import Icon from '@amzn/meridian/icon';

import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import { SidebarLayoutOptions, Option } from './SidebarLayoutData';

interface SidebarLayoutProperties {
  displayedLinks: string[];
}

const SidebarLayout: FunctionComponent<SidebarLayoutProperties> = (props) => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const navigate = useNavigate();
  const location = useLocation();
  const onClickLink = useCallback(
    (href) => {
      navigate(href);
    },
    [navigate],
  );

  return (
    <SideMenu open={open} onOpen={onOpen} width="240px" onClose={onClose} type="skinny">
      {SidebarLayoutOptions.filter((value) => props.displayedLinks.includes(value.href)).map((option: Option) => (
        <SideMenuLink
          key={option.href}
          href={option.href}
          onClick={onClickLink}
          icon={option.icon}
          selected={`/${location.pathname.split('/').pop()}` === option.href}
        >
          {option.title}
        </SideMenuLink>
      ))}
    </SideMenu>
  );
};

export default SidebarLayout;
