import { API } from './API'; // Use your custom API wrapper
import { getUrl, list } from 'aws-amplify/storage';
import { DimOptions, DropdownAttributes } from './Types';

const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

export const encodeSpaces = (str: string): string => str.replace(/ /g, '%20');

export const makeValidJSONKey = (str: string) => {
  // Replace any non-word characters (except underscore) with an underscore
  const cleanedStr = str.replace(/\W+(?!_)/g, '_');

  // Prepend an underscore if the first character is a digit
  const validKey = /^\d/.test(cleanedStr) ? `_${cleanedStr}` : cleanedStr;

  return validKey;
};

export async function downloadFromS3(fileKey: string, fileName: string) {
  try {
    const { url } = await getUrl({ key: fileKey });
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();
    downloadBlob(blob, fileName);
  } catch (error) {
    console.error('Error downloading file from S3:', error);
  }
}

export async function waitForS3File(fileKey: string, waitMS: number, attempts: number) {
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  for (let attempt = 0; attempt < attempts; attempt++) {
    try {
      const result = await list({ prefix: fileKey });
      console.log(result.items.length);
      if (result.items.length > 0) break;
    } catch (error) {
      console.error('Error listing S3 files:', error);
    }
    await delay(waitMS);
  }
}

export async function getDropdownAttributes(username: string, projectName: string, projectEnv: string) {
  const apiCallParam = {
    headers: {},
    queryStringParameters: {
      operation: 'getDropdownValues',
      userId: username,
      projectName: projectName,
      projectEnv: projectEnv,
    },
  };
  try {
    const data = await API.get<any>('FireboltWebsiteAPIs', '/reviewdata', apiCallParam);
    const tmpDropdownAttributes: DropdownAttributes = {
      MetricList: data.MetricList,
      DimList: data.DimList,
      UploadTimes: data.UploadTimes,
    };
    const returnDropdownAttributes: {
      DropdownAttributes?: DropdownAttributes;
      DisplaySelecteddimList: DimOptions;
    } = {
      DisplaySelecteddimList: {},
    };
    if (tmpDropdownAttributes.MetricList.length > 0) {
      returnDropdownAttributes['DropdownAttributes'] = tmpDropdownAttributes;

      const tmpDisplaySelecteddimList: DimOptions = {};
      Object.keys(data.DimList).forEach((e) => (tmpDisplaySelecteddimList[e] = []));
      returnDropdownAttributes['DisplaySelecteddimList'] = tmpDisplaySelecteddimList;
    }
    return returnDropdownAttributes;
  } catch (error) {
    console.error('Error fetching dropdown attributes:', error);
    return { DisplaySelecteddimList: {} };
  }
}

/*
export async function getDropdownAttributes(username: string, projectName: string, projectEnv: string) {
    const apiCallParam = {
        headers: {},
        queryStringParameters: {
            operation: 'getDropdownValues',
            userId: username,
            projectName: projectName,
            projectEnv: projectEnv
        },
    };
    try {
        const data = await API.get<any>("FireboltWebsiteAPIs", '/reviewdata', apiCallParam);
        const tmpDropdownAttributes: DropdownAttributes = {
            MetricList: data.MetricList,
            DimList: data.DimList,
            UploadTimes: data.UploadTimes
        };
        const returnDropdownAttributes: {
            DropdownAttributes?: DropdownAttributes,
            DisplaySelecteddimList?: Record<string, any[]>
        } = {};
        if (tmpDropdownAttributes.MetricList.length > 0) {
            returnDropdownAttributes['DropdownAttributes'] = tmpDropdownAttributes;

            const tmpDisplaySelecteddimList: Record<string, any[]> = {};
            Object.keys(data.DimList).forEach(e => tmpDisplaySelecteddimList[e] = []);
            returnDropdownAttributes['DisplaySelecteddimList'] = tmpDisplaySelecteddimList;
        }
        return returnDropdownAttributes;
    } catch (error) {
        console.error('Error fetching dropdown attributes:', error);
        return {};
    }
}
    */
